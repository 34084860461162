import { RIReleaseData } from "./services/api/generated.RibesProtocol";
import store from 'store'

export type LogLevelString = 'off'|'fatal'|'error'|'warning'|'information'|'debug'|'verbose'|string;

/**
 * Language definition
 */
export interface LanguageDef
{
    /**
     * Language shortcut used
     */
    lang: string;

    /**
     * Language display name
     */
    name: string;
}

export interface SettingsConfiguration
{
    /**
     * Indication that application is running in debug mode
     */
    debug: boolean;

    /**
     * Base url that is used for accessing REST api
     */
    apiBaseUrl: string;

    /**
     * Base url that is used for accessing Management REST api
     */
    managementBaseUrl: string;

    /**
     * Object hodling default headers that are send with rest requests
     */
    defaultApiHeaders: { [key: string]: string };

    /**
     * Array of available themes
     */
    themes: string[];

    /**
     * Available languages for application
     */
    languages: LanguageDef[];

    /**
     * Indication whether is AVRO adapter enabled
     */
    avroEnabled: boolean;

    /**
     * Indication whether application should emulate electron
     */
    emulateElectron: boolean;

    /**
     * Indication whether aws installation in cloud is enabled.
     * Equals to `!!recaptchaSiteKey`
     */
    awsEnabled: boolean;
    /**
     * Nezobrazuje informacie o datume/case udelenia suhlasov
     */
    hideCookieInfo: boolean;
    /**
     * google recaptcha site key
     */
    recaptchaSiteKey: string;
    /**
     * linkedIn site key
     */
    linkedInSiteKey: string;
    /**
     * linkedIn scopes
    */
    linkedInScope : string;
    /**
     * true if has linkedIn functionality
    */
    linkedInEnabled : boolean;
}

export interface SettingsGeneral
{
    /**
     * Default visual theme of application
     */
    theme: string;

    /**
     * Default selected language
     */
    language: string;
}

export interface SettingsDebug
{
    /**
     * Indication whether is console (logger sink) enabled
     */
    consoleEnabled: boolean;

    /**
     * Indication whether are debug data enabled
     */
    debugData: boolean;

    /**
     * Indication whether are debug data enabled
     */
    expertMode: boolean;
}

export interface SettingsLogging
{
    /**
     * Minimal log level for console sink
     */
    consoleLogLevel: LogLevelString;

    /**
     * Minimal log level for file sink
     */
    fileLogLevel: LogLevelString;

    /**
     * Path on which are file logs stored
     */
    fileLogPath?: string;
}

/**
 * Configuration object
 */
export interface Configuration
{
    /**
     * Static configuration for application
     */
    configuration?: SettingsConfiguration;

    /**
     * General settings
     */
    general?: SettingsGeneral;

    /**
     * Debug settings, used for debugging purposes
     */
    debug?: SettingsDebug;

    /**
     * Logging setting, allows to configure logger sinks
     */
    logging?: SettingsLogging;

    /**
     * Remote config
     */
    remote?: RIReleaseData
}

export const config: Configuration =
{
};

export function resetRemoteConfig(remote: RIReleaseData): void {
    config.remote = remote
    const LAST_ARBROR_VERSION: string = store.get("ARBOR_LARIX_VERSION")
    if (remote.releaseId.id !== "ERROR-ID" && LAST_ARBROR_VERSION != remote.releaseId.id) {
        console.log(`Mazem cache pretoze ${LAST_ARBROR_VERSION} != ${remote.releaseId.id}`)
        store.clearAll() // na poradi zalezi
        store.set("ARBOR_LARIX_VERSION", remote.releaseId.id)
    }
}


export function setCookie(name: string, value: string, expireDays: number): void {
    const d = new Date()
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000)
    const expires = `expires=${d.toUTCString()}`
    document.cookie = `${name}=${value}; ${expires}`
    // console.log(`setCookie ${name}:${value} => expires:${expires}`)
}

export function getCookie(name: string): string {
    const ca: Array<string> = document.cookie.split(';')
    const caLen: number = ca.length
    const cookieName = `${name}=`
    var c: string

    for (let i: number = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '')
        if (c.indexOf(cookieName) == 0) {
            return c.substring(cookieName.length, c.length)
        }
    }
    return ''
}

export function deleteCookie(name): void {
    setCookie(name, '', 0)
}
